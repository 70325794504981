import React from "react"

class accordion extends React.Component{
  constructor(){
		super();

		this.state = {
      openAccordion:false
		}
  }

  toggleResponse = (book) =>{
    if(this.state.openAccordion === true){	
      localStorage.setItem('openAccordion', false);
      this.setState({openAccordion:false});
    }else{			
      localStorage.setItem('openAccordion', true);
      this.setState({openAccordion:true});
    }
  };

  render(){
    return(         
      <div className="card">
        <div className="card-header" id="headingOne">
          <h2 className="mb-0">
            <button onClick={this.toggleResponse.bind(this, 'bananes')} type="button" className="btn btn-link" data-toggle="collapse" data-target="#collapseOne"><i className={this.state.openAccordion === false ? 'fa fa-plus' : 'fa fa-minus'}></i>{ this.props.title }</button>									
          </h2>
        </div>
        <div id="collapseOne" className={this.state.openAccordion === false ? 'collapse' : 'collapse show'} aria-labelledby="headingOne" data-parent="#accordionExample">
          <div className="card-body">
            <p dangerouslySetInnerHTML={{__html: this.props.content}} />
          </div>
        </div>
      </div>
    )
  }   
};

export default accordion
